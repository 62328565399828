import { FC, useEffect, useState } from 'react';
import Title from './Title';
import Localization from './Localization';
import Theme from './Theme';
import PersonCard from './PersonCard';
import Info from './Info';
import Timeline from './Timeline';
import SteamGiftService from '../api/services/SteamGiftService';
import { useQuery } from 'react-query';
import Loader from './Loader';
import { useLocation } from 'react-router-dom';
import setThemeAttribute from '../utils/setThemeAttribute';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { ThemeType } from '../types/theme';
import { LocalizationType } from '../types/localization';
import Error from './Error';


const SteamGift : FC<{}> = () => {
    const [theme,setTheme] = useLocalStorage<ThemeType>("theme","dark");
    const [locale,setLocale] = useLocalStorage<LocalizationType>('locale',"RU");
    const [uniqueCode,setUniqueCode] = useState<string | null>(null);
    const [data,setData] = useState<any>({})

    const location = useLocation();

    const [shouldRefetch,setShouldRefetch] = useState<boolean>(false);
    const [shouldAdd,setShouldAdd] = useState<boolean>(false);
    const refetchStatusTimeout = 20000;


    const checkData = (data : any) => {
        if (!!data) {
            setShouldRefetch(true);
            setData(data);
        } else {
            setShouldAdd(true);
        }
    }

    const {isLoading : isInfoLoading,isError : isInfoFetchError} = useQuery(
        ["info_order", uniqueCode],
        () => SteamGiftService.getOrderInfo(uniqueCode || ""),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true,
            enabled: !!uniqueCode,
            onSuccess: (response) => {
                setShouldRefetch(false);
                if (!shouldRefetch) {
                    checkData(response.data);
                } else {
                    setData(response.data);
                }
            }
        }
    );

    const {isLoading: isUpdatingLoading, isError: isUpdatingFetchError} = useQuery(
        ["new_order", uniqueCode],
        () => SteamGiftService.addOrder(uniqueCode || ""),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true,
            enabled: !!uniqueCode && shouldAdd,
            onSuccess: (response) => {
                setShouldRefetch(true);
                setShouldAdd(false);
                setData(response.data);
            }
        }
    );

    const {data : status} = useQuery(
        ["status_order", uniqueCode],
        () => SteamGiftService.getOrderStatus(uniqueCode || ""),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true,
            enabled: shouldRefetch,
            refetchInterval:  refetchStatusTimeout,
            onSuccess: (status) => {
                if (status.status === "Ваш заказ успешно завершен.") {
                    setShouldRefetch(false);
                }
            }
        }
    );

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const uniqueCode = params.get("uniquecode");
        params.forEach((value, key) => {
            if (key !== 'uniquecode') {
                params.delete(key);
            }
        });
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState({}, document.title, newUrl);
        setUniqueCode(uniqueCode);
    }, []);

    useEffect(() => {
        setThemeAttribute(theme);
    }, [theme]);


    if (isInfoLoading) {
        return <Loader type="loaderFind" locale={locale} />
    }

    if (isUpdatingLoading) {
        return <Loader type="loaderProcess" locale={locale} />
    }

    if (isInfoFetchError || isUpdatingFetchError || !uniqueCode) {
        return <Error locale={locale} />
    }

    return (
        <>
            <div className="container">
                <header className="header">
                    <Title locale={locale} />
                    <section className="header__tools">
                        <Localization locale={locale} setLocale={setLocale} />
                        <Theme theme={theme} setTheme={setTheme} />
                    </section>
                </header>
                <section className="main">
                    <div className="main__wrapper">
                        <PersonCard data={data} locale={locale} />
                        <div className="main_info">
                            <Info
                                uniqueCode={uniqueCode}
                                data={data}
                                locale={locale}
                            />
                            <Timeline
                                status={status?.status}
                                locale={locale}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </>

    );
};

export default SteamGift;