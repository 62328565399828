import axios from 'axios';
import { api_new_order, api_order_info, api_status_order } from '../api';

class SteamGiftService {
    public static async getOrderInfo(uniqueCode: string) {
        const response = await axios.get(api_order_info,{
            params: {
                uniquecode: uniqueCode
            }
        });

        return response.data;
    }

    public static async getOrderStatus(uniqueCode: string) {
        const response = await axios.get(api_status_order,{
            params: {
                uniquecode: uniqueCode
            }
        });

        return response.data;
    }

    public static async addOrder(uniqueCode: string) {
        //const response = await axios.get(api_new_order)
        const response = await axios.post(api_new_order,{uniquecode: uniqueCode});

        return response.data;
    }
}

export default SteamGiftService;