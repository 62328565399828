import {FC} from "react";
import { FaTelegram } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import { localization, localizationCurrency } from '../localization/localization';
import { LocalizationType } from '../types/localization';
import { FaBitcoinSign } from "react-icons/fa6";
import { FaRubleSign } from "react-icons/fa";
import { IoLogoUsd } from "react-icons/io5";
import { FaEuroSign } from "react-icons/fa";

interface PersonCardProps {
    data: any,
    locale:LocalizationType,
}

const PersonCard : FC<PersonCardProps> = ({data = {},locale}) => {

    const currencyIcon: any = {
        WMZ: <IoLogoUsd />,
        WMR: <FaRubleSign />,
        WME: <FaEuroSign />,
        WMX: <FaBitcoinSign />,
    }

    return (
        <div className="person_card">
            {/*<div className="person_card__image_block">*/}
            {/*    <img className="person_card__image"*/}
            {/*         src="https://vkplay.ru/hotbox/content_files/Stories/2023/11/23/9fd7805a9b1943c6ad1767a122bf39f8.jpg"*/}
            {/*         alt="Изображение купленного товара" />*/}
            {/*</div>*/}
            <h2 className="person_card__date">
                <span className="person_card__date__title">
                    {localization[locale].buyTime}:
                </span>
                <span className="person_card__date__time">{data.date_pay}</span>
            </h2>
            <h1 className="person_card__title">
                {localization[locale].infoTitle}
            </h1>

            <div className="person_card__description_block">
                <h1 className="person_card__description__title">
                    {localization[locale].getSum}:
                </h1>
                <h1 className="person_card__description__content">
                    <span>{data.cnt_goods || 123}</span>
                    <span>{currencyIcon[data.type_curr]}</span>
                </h1>
            </div>

            <div className="person_card__description_block">
                <h1 className="person_card__description__title">
                    {localization[locale].currency}:
                </h1>
                <h1 className="person_card__description__content">
                    {localizationCurrency[data.type_curr] ||
                        localizationCurrency['WMR']}
                </h1>
            </div>

            <div className="person_card__description_block">
                <h1 className="person_card__description__title">
                    {localization[locale].paidAmount}:
                </h1>
                <h1 className="person_card__description__content">
                    <span>{data.amount || 123}</span>{' '}
                    <span>{currencyIcon[data.type_curr]}</span>
                </h1>
            </div>

            <div className="person_card__contacts">
                <h1 className="person_card__contacts__title">
                    {localization[locale].contacts}
                </h1>
                <div className="person_card__contacts__icons">
                    {/*<BsDiscord className="person_card__contacts__icons__icon" />*/}
                    <a href="https://t.me/suppfory" target="_blank">
                        <FaTelegram className="person_card__contacts__icons__icon" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PersonCard;