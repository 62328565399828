import { FC, useEffect } from 'react';
import { ThemeType } from '../types/theme';
import { FaRegLightbulb } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";

enum ThemesChanged {
    dark = "light",
    light = "dark",
}

interface ThemeProps {
    theme: ThemeType;
    setTheme: (newTheme: ThemeType) => void;
}

const Theme : FC<ThemeProps> = ({theme,setTheme}) => {

    const handleThemeChange = (theme : ThemeType) => {
        setTheme(ThemesChanged[theme])
    }

    return (
        <div className="header__tools__item theme" onClick={() => handleThemeChange(theme)}>
            {
                {
                    light: <FaRegLightbulb className="header__tools__item__content theme_icon" />,
                    dark: <FaLightbulb className="header__tools__item__content theme_icon"  />,
                }[theme]
            }
        </div>
    );
};

export default Theme;