export const localization = {
    RU: {
        title: "Ваш заказ",
        subtitle: "Инфо и контроль выполнения заказа",
        buyTime: "Время покупки товара",
        contacts: "Связаться с продавцом, указав уникальный код",
        profile: "Steam Профиль",
        code: "Уникальный Код Товара",
        infoTitle: "Информация о платеже",
        region: "Регион",
        bot: "Бот",
        getSum: "Зачисленная сумма",
        currency: "Валюта платежа",
        paidAmount: "Оплаченная сумма",
        status: "Статус выполнения заказа",
        firstStep: "Уникальный код товара успешно принят",
        secondStep: "Заказ ожидает распределения и проверки",
        thirdStep: "Заказ распределён и обрабатывается на сервере",
        fourStep: "Завершение обработки заказа",
        loaderFind: "Подождите, идет проверка уникального кода",
        loaderProcess: `Спасибо за покупку!<br /> Ваш заказ был успешно отправлен на обработку.<br /> В скором времени, вас перекинет на страницу статуса заказа.`,
        errorTitle: "Произошла ошибка обработки!",
        errorSubtitle: "Пожалуйста, обратитесь в тех.поддержку, указав свой уникальный код!",
        email: "Почта",
        idGoods: "Идентификатор товара",
        page404: "Данной страницы не существует"
    },
    ENG: {
        title: "Your order",
        subtitle: "Information and order control",
        buyTime: "Product purchase time",
        contacts: "Contact the seller using a unique code",
        profile: "Steam Profile",
        code: "Unique Product Code",
        infoTitle: "Payment information",
        region: "Region",
        bot: "Bot",
        getSum: "Amount credited",
        currency: "Payment currency",
        paidAmount: "Paid amount",
        status: "Order Fulfillment Status",
        firstStep: "Unique product code successfully accepted",
        secondStep: "The order is awaiting distribution and verification",
        thirdStep: "The order is distributed and processed on the server",
        fourStep: "Completing order processing",
        loaderFind: "Please wait, the unique code is being checked",
        loaderProcess: `Thank you for your purchase!<br /> Your order has been successfully submitted for processing.<br /> You will soon be redirected to the order status page`,
        errorTitle: "A processing error occurred!",
        errorSubtitle: "Please contact technical support, indicating your unique code!",
        email: "Email",
        idGoods: "Your order ID",
        page404: "This page does not exist"
    }

}

export const localizationStatus : any  = {
    RU: {
        "Ваш заказ ожидает распределения.": "Ваш заказ ожидает распределения.",
        "Ваш аккаунт не найден.": "Ваш аккаунт не найден.",
        "Ваш заказ обрабатывается на сервере.": "Ваш заказ обрабатывается на сервере.",
        "Ваш заказ успешно завершен.": "Ваш заказ успешно завершен.",
        "Проблема с обработкой вашего заказа.": "Проблема с обработкой вашего заказа.",
        "Проблема с оплатой вашего заказа.": "Проблема с оплатой вашего заказа.",
        "Проблема с аккаунтом, указанным в заказе.": "Проблема с аккаунтом, указанным в заказе.",
        "Возникли технические проблемы с вашим заказом.": "Возникли технические проблемы с вашим заказом.",
        "Проблема с лимитами для вашего заказа.": "Проблема с лимитами для вашего заказа.",
        "Ошибка на стороне поставщика.": "Ошибка на стороне поставщика.",
        "Неизвестный статус": "Неизвестный статус",
    },
    ENG: {
        "Ваш заказ ожидает распределения.": "Your order is awaiting distribution.",
        "Ваш аккаунт не найден.": "Your account was not found.",
        "Ваш заказ обрабатывается на сервере.": "Your order is being processed on the server.",
        "Ваш заказ успешно завершен.": "Your order has been completed successfully.",
        "Проблема с обработкой вашего заказа.":  "There's a problem processing your order.",
        "Проблема с оплатой вашего заказа.":   "There is a problem with payment for your order.",
        "Проблема с аккаунтом, указанным в заказе.":  "There is a problem with the account specified in the order.",
        "Возникли технические проблемы с вашим заказом.":  "There are technical problems with your order.",
        "Проблема с лимитами для вашего заказа.":  "There's a problem with your order limits.",
        "Ошибка на стороне поставщика.":  "Error on the supplier side.",
        "Неизвестный статус":  "Unknown status",
    }
}

export const localizationCurrency : any = {
    WMZ: "USD",
    WMR: "RUB",
    WME: "EUR",
    WMX: "BTC",
}