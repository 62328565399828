import { FC } from 'react';
import InfoItem from './InfoItem';
import { InfoItemType } from '../types/info';
import { localization } from '../localization/localization';
import { LocalizationType } from '../types/localization';

interface InfoProps {
    uniqueCode?: string,
    data: any,
    locale:LocalizationType
}

const Info : FC<InfoProps> = ({uniqueCode,data,locale }) => {

    const infoItems: InfoItemType[] = [
        // {id:1,title:localization[local].profile, children:"https://my.digiseller.com/inside/api_general.asp#searchuniquecodehttps://my.digiseller.com/inside/api_general.asp#searchuniquecode"},
        {id:1,title:localization[locale].idGoods, children:data.id_goods || "124243"},
        {id:2,title:localization[locale].email, children:data.email || "ivanov@gmail.com"},
        {id:3,title:localization[locale].code, children:uniqueCode || "Уникальный код товара"},
        // {id:4,title:localization[local].region, children:"Россия"},
        // {id:5,title:localization[local].bot, children:"MaxWell"}
    ]

    return (
        <div className="info">
            <div className="info__wrapper">
                {infoItems.map(item => (
                    <InfoItem key={item.id} title={item.title}>
                        {item.children}
                    </InfoItem>
                ))}
            </div>
        </div>
    );
};

export default Info;