import { FC } from 'react';

import { useAppSelector } from '../../hooks/redux';
import cn from 'classnames';
import {
    TransitionGroup,
    Transition,
    CSSTransition,
} from 'react-transition-group';

import Message from './Message';

const MessagesList: FC<{}> = () => {
    const data = useAppSelector((state) => state.messages.data);

    return (
        <Transition in={data.length > 0} timeout={300} unmountOnExit>
            {(state) => (
                <div className={cn('messages_list', state)}>
                    <TransitionGroup component={null}>
                        {data.map((message, idx) => (
                            <CSSTransition
                                key={idx.toString()}
                                timeout={300}
                                classNames="message"
                            >
                                <Message
                                    type={message.type}
                                    message={message.message}
                                />
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            )}
        </Transition>
    );
};

export default MessagesList;
