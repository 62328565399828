import { MessageProps } from '../../types/messages.types';
import { FC } from 'react';
import { AiFillCheckCircle, AiFillInfoCircle } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';
import Icon from '../ui/Icon';

const Message: FC<MessageProps> = ({ message, type }) => {
    return (
        <div className="messages_list__item">
            {
                {
                    success: (
                        <Icon
                            className="messages_list__item__icon message_icon_success"
                            icon={AiFillCheckCircle}
                        />
                    ),
                    error: (
                        <Icon
                            className="messages_list__item__icon message_icon_error"
                            icon={RiErrorWarningFill}
                        />
                    ),
                    warning: (
                        <Icon
                            className="messages_list__item__icon message_icon_warning"
                            icon={RiErrorWarningFill}
                        />
                    ),
                    info: (
                        <Icon
                            className="messages_list__item__icon message_icon_info"
                            icon={AiFillInfoCircle}
                        />
                    ),
                }[type]
            }
            <span className="messages_list__item__message">{message}</span>
        </div>
    );
};

export default Message;
