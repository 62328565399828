import { Message } from '../../types/messages.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

interface MessageSliceType {
    data: Message[];
    timeout: number;
}

const initialState: MessageSliceType = {
    data: [],
    timeout: 2500,
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        pushMessage: (state, action: PayloadAction<Message>) => {
            state.data = [...state.data, action.payload];
        },
        removeMessage: (state) => {
            state.data.shift();
        },
    },
});

export const openMessage =
    (message: Message): AppThunk =>
    async (dispatch, getState) => {
        const timeout = message.timeout || getState().messages.timeout;

        dispatch(pushMessage(message));
        setTimeout(() => {
            dispatch(removeMessage());
        }, timeout);
    };

const { pushMessage, removeMessage } = messagesSlice.actions;

export default messagesSlice.reducer;
