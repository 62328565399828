import { FC } from 'react';
import { LocalizationType } from '../types/localization';

interface LocalizationProps {
    locale: LocalizationType;
    setLocale: (locale: LocalizationType) => void;
}

const Localization : FC<LocalizationProps> = ({locale,setLocale}) => {

    const onLocaleClick = () => {
        setLocale(locale === "RU" ? "ENG" : "RU");
    }

    return (
        <div className="header__tools__item localization" onClick={onLocaleClick}>
            <h1 className="header__tools__item__content localization__locale">{locale}</h1>
        </div>
    );
};

export default Localization;