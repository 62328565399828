import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SteamGift from './SteamGift';
import { QueryClient, QueryClientProvider } from 'react-query';
import MessagesList from './Messages/MessagesList';
import Page404 from './Page404';

const queryClient = new QueryClient();

const App : FC<{}> = () => {

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<SteamGift />} />
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
            <MessagesList />
        </>

    );
};

export default App;
