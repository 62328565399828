import { FC, useEffect, useState } from 'react';
import { localization, localizationStatus } from '../localization/localization';
import { LocalizationType } from '../types/localization';
import TimelineItem from './TimelineItem';
import { TimelineItemType } from '../types/timeline';
import { MdError } from "react-icons/md";

interface TimelineProps {
    status: string,
    locale: LocalizationType
}

const Timeline : FC<TimelineProps> = ({status = "",locale}) => {

    const status_mapping  = {

            "Ваш заказ ожидает распределения.": 2,
            "Ваш аккаунт не найден.": -1,
            "Ваш заказ обрабатывается на сервере.": 3,
            "Ваш заказ успешно завершен.": 4,
            "Проблема с обработкой вашего заказа.": -1,
            "Проблема с оплатой вашего заказа.": -1,
            "Проблема с аккаунтом, указанным в заказе.": -1,
            "Возникли технические проблемы с вашим заказом.": -1,
            "Проблема с лимитами для вашего заказа.": -1,
            "Ошибка на стороне поставщика.": -1,
            "Неизвестный статус": -1,

    }

    const [steps,setSteps] = useState<TimelineItemType[]>([
        {number:1,step:"firstStep",text:localization[locale].firstStep,status:"done"},
        {number:2,step:"secondStep",text:localization[locale].secondStep, status: "active"},
        {number:3,step:"thirdStep",text:localization[locale].thirdStep, status: "inactive"},
        {number:4,step:"fourStep",text:localization[locale].fourStep, status: "inactive"},
    ]);
    const [error,setError] = useState<boolean>(false);

    const updateSteps = (currentStep: number) => {
        setSteps(steps.map(step => {
            if (currentStep === -1) {
                // @ts-ignore
                return { ...step,text:localization[locale][step.step], status: "inactive" };
            }
            if (step.number < currentStep) {
                // @ts-ignore
                return { ...step,text:localization[locale][step.step], status: "done" };
            } else if (step.number === currentStep) {
                // @ts-ignore
                return { ...step,text:localization[locale][step.step], status: "active" };
            } else {
                // @ts-ignore
                return { ...step,text:localization[locale][step.step], status: "inactive" };
            }
        }));
    };

    useEffect(() => {
        // @ts-ignore
        const number = status_mapping[status];
        setError(number === -1);
        updateSteps(number);

    }, [status,locale]);


    return (
        <div className="timeline">
            {error && (
                <div className="timeline__error">
                    <MdError className="timeline__error__icon" />
                    <h1 className="timeline__error__status">{localizationStatus[locale][status]}</h1>
                </div>
            )}
            <div className="timeline__wrapper">
                <h1 className="timeline__title">
                    {localization[locale].status}
                </h1>
                <div className="timeline__items">
                    {steps.map((step) => (
                        <TimelineItem
                            key={step.number}
                            number={step.number}
                            text={step.text}
                            status={step.status}
                            step={step.step}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Timeline;