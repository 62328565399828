import { FC } from 'react';
import { FaTelegram } from 'react-icons/fa';
import { LocalizationType } from '../types/localization';
import { localization } from '../localization/localization';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Link } from 'react-router-dom';


const Page404 : FC<{}> = () => {
    const [locale] = useLocalStorage<LocalizationType>("locale","RU");

    return (
        <div className="error">
            <h1 className="error__title">StoForY</h1>
            <h1 className="error__text_title">{localization[locale].page404}</h1>
            {/*<Link to={"/"} className="error__text_link" >Вернуться</Link>*/}
            <a href="https://t.me/suppfory" target="_blank">
                <FaTelegram className="person_card__contacts__icons__icon" />
            </a>
        </div>
    );
};

export default Page404;