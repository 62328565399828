
import { FC } from 'react';
import cn from 'classnames';
import { IconProps } from '../../types/icon.types';

const Icon: FC<IconProps> = ({ icon: Icon, className, ...other }) => {
    return Icon ? <Icon className={cn('icon', className)} {...other} /> : null;
};

export default Icon;
