const MOD  = process.env.REACT_APP_MOD;

const urls ={
    "DEV_REACT": process.env.REACT_APP_API_URL_DEV_REACT,
    "DEV_FASTAPI": process.env.REACT_APP_API_URL_DEV_FASTAPI,
    "PROD": process.env.REACT_APP_API_URL_PROD,
};

// @ts-ignore
const url = urls[MOD];

export {url,MOD};