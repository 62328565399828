import { FaCartShopping } from "react-icons/fa6";
import { localization } from '../localization/localization';
import { LocalizationType } from '../types/localization';
import { FC } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface TitleProps {
    locale: LocalizationType;
}

const Title : FC<TitleProps> = ({locale}) => {

    return (
        <div className="page_title">
            <div className="page_title__icon_block">
                <FaCartShopping className="page_title__icon_block__icon" />
            </div>
            <div className="page_title__info">
                <h1 className="page_title__title">
                    {localization[locale].title}
                </h1>
                <h2 className="page_title__subtitle">
                    {localization[locale].subtitle}
                </h2>
            </div>
        </div>

    );
};

export default Title;