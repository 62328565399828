import { FC } from 'react';
import { SiIconfinder } from "react-icons/si";
import { GiProcessor } from "react-icons/gi";
import { LocalizationType } from '../types/localization';
import { localization } from '../localization/localization';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface LoaderProps {
    type:"loaderFind" | "loaderProcess",
    locale: LocalizationType,
}


const Loader : FC<LoaderProps> = ({type,locale}) => {

    return (
        <div className="loader">
            <h1 className="loader__title">StoForY</h1>
            <h1
                className="loader__text"
                dangerouslySetInnerHTML={{ __html: localization[locale][type] }}
            />
            <span className="loader__spin"></span>
            {/*{*/}
            {/*    {*/}
            {/*        loaderFind: <SiIconfinder className="loader__icon" />,*/}
            {/*        loaderProcess: <GiProcessor className="loader__icon" />,*/}
            {/*    }[type]*/}
            {/*}*/}
        </div>
    );
};

export default Loader;