import { FC } from 'react';
import { FaTelegram } from 'react-icons/fa';
import { LocalizationType } from '../types/localization';
import { localization } from '../localization/localization';

interface ErrorProps {
    locale:LocalizationType;
}

const Error : FC<ErrorProps> = ({locale}) => {
    return (
        <div className="error">
            <h1 className="error__title">StoForY</h1>
            <h1 className="error__text_title">{localization[locale].errorTitle}</h1>
            <h1 className="error__text_subtitle">{localization[locale].errorSubtitle}</h1>
            <a href="https://t.me/suppfory" target="_blank">
                <FaTelegram className="person_card__contacts__icons__icon" />
            </a>
        </div>
    );
};

export default Error;