import { FC } from 'react';
import { TimelineItemType } from '../types/timeline';
import cn from "classnames";

interface TimelineItemProps extends TimelineItemType {}

const TimelineItem : FC<TimelineItemProps> = ({number,text, status}) => {
    return (
        <div className="timeline__item">
            <div className={cn("timeline__item__wrapper",status)}>
                <div className="timeline__item__number_block">
                    <h1 className="timeline__item__number">{number}</h1>
                </div>
                <h1 className="timeline__item__text">
                    {text}
                </h1>
            </div>
        </div>
    );
};

export default TimelineItem;