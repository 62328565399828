import { url,MOD } from "./config";

const api_paste = (local: string, python: string) => {
    return MOD === "DEV_REACT" ? local : python;
}

export const api_status_order = `${url}${api_paste("/status_order/","/api/v1/digiseller_orders/status_order/")}`;
export const api_new_order = `${url}${api_paste("/new_order/","/api/v1/digiseller_orders/order/")}`;
export const api_order_info = `${url}${api_paste("/info_order/","/api/v1/digiseller_orders/order/")}`;

