import { ThemeType } from '../types/theme';

const setThemeAttribute = (theme: ThemeType) => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
        bodyElement.removeAttribute("theme");
        bodyElement.setAttribute("theme",theme);
    }
}

export default setThemeAttribute;