import { FC, useState } from 'react';
import cn from 'classnames';
import { FaCopy } from 'react-icons/fa';
import { copy } from '../utils/copy';
import { useAppDispatch } from '../hooks/redux';
import { openMessage } from '../store/reducers/messagesSilce';

interface InfoItemProps {
    title: string;
    children: string;
}

const InfoItem : FC<InfoItemProps> = ({title,children}) => {

    const dispatch = useAppDispatch();
    const [isCopy,setIsCopy] = useState<boolean>(false);

    const handleCopy = async (text : string) => {
        const copyFlag = await copy(text);
        if (copyFlag) {
            dispatch(openMessage({
                type:"success",
                message:"Текст успешно скопирован"
            }));
        } else {
            dispatch(openMessage({
                type:"error",
                message:"Ошибка копирования"
            }));
        }
    }

    return (
        <div
            className={cn("info__item",(isCopy ? "active" : "unactive"))}

        >
            <h1 className="info__item__title">
                {title}
            </h1>
            <h2 className="info__item__content"
                onMouseEnter={() => setIsCopy(true)}
                onMouseLeave={() => setIsCopy(false)}
                onClick={() => handleCopy(children)}
            >
                {children}
                <FaCopy className="info__item__content__copy" />
            </h2>
        </div>
    );
};

export default InfoItem;